import RestService from '@/services/RestService'

let resourceName = 'machineType'

class MachineTypeService extends RestService {
}

const machineTypeService = new MachineTypeService(resourceName)

export default machineTypeService
