<template>
  <div class="g20-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g20Service from '@/services/g20Service'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import tenderService from '@/services/tenderService'
import auth from '@/common/auth'
import utility from '@/common/utility'
import machineTypeService from '@/services/machineTypeService'

export default {
  name: 'G20TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '材料备料日统计汇总表',
        columns: [
          {
            label: '机械设备名称',
            dataKey: 'machineName',
            width: 200
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          }
        ]
      },
      defaultDataList: [],
      tenderStatList: [],
      g20Map: {},
      reportDate: '',
      tenders: [],
      machineTypes: [],
      engineeringType: this.$route.query.engineeringType || '土建'
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      this.$refs.mySpread.exportExcel('在场主要机械设备日统计汇总表.xlsx')
    },
    generateMapData () {
      this.g20Map = {}
      this.machineTypes.forEach(item => {
        this.g20Map[`${utility.trim(item.machineName)}`] = {
          machineName: item.machineName,
          unit: item.unit
        }
      })
      console.log('map', this.g20Map)

      this.tenderStatList.forEach(list => {
        if (list.length !== 0) {
          const item = list[0]
          const column = {
            label: item.tenderName === 'total' ? '汇总' : item.tenderName,
            children: [
              {
                label: '合同规定进场数量',
                dataKey: `${item.tenderName}_contractNum`,
                width: 100
              },
              {
                label: '已进场数量',
                dataKey: `${item.tenderName}_realNum`,
                width: 100
              },
              {
                label: '本日进场数量',
                dataKey: `${item.tenderName}_currentDateNum`,
                width: 100
              },
              {
                label: '本月进场数量',
                dataKey: `${item.tenderName}_currentMonthNum`,
                width: 100
              },
              {
                label: '累计进场数量',
                dataKey: `${item.tenderName}_accumulateNum`,
                width: 100
              }
            ]
          }
          this.spreadInfo.columns.push(column)
        }
        list.forEach(item => {
          console.log('item', item)
          this.g20Map[`${utility.trim(item.machineName)}`][`${item.tenderName}_contractNum`] = item.contractNum
          this.g20Map[`${utility.trim(item.machineName)}`][`${item.tenderName}_currentMonthNum`] = item.currentMonthNum
          this.g20Map[`${utility.trim(item.machineName)}`][`${item.tenderName}_currentDateNum`] = item.currentDateNum
          this.g20Map[`${utility.trim(item.machineName)}`][`${item.tenderName}_accumulateNum`] = item.accumulateNum
          this.g20Map[`${utility.trim(item.machineName)}`][`${item.tenderName}_realNum`] = item.realNum
          this.g20Map[`${utility.trim(item.machineName)}`][`${item.tenderName}_code`] = item.code
        })
      })

      for (let key of Object.keys(this.g20Map)) {
        const item = this.g20Map[key]
        this.defaultDataList.push(item)
      }

      let bigSum = {
        machineName: '大型机械',
        machineSpec: '',
        unit: '台'
      }

      let middleSum = {
        machineName: '中小型机械',
        machineSpec: '',
        unit: '台'
      }

      // initialize bigSum and middleSum
      this.tenders.forEach(tender => {
        bigSum[`${tender.tenderName}_contractNum`] = 0
        bigSum[`${tender.tenderName}_currentMonthNum`] = 0
        bigSum[`${tender.tenderName}_currentDateNum`] = 0
        bigSum[`${tender.tenderName}_accumulateNum`] = 0
        bigSum[`${tender.tenderName}_realNum`] = 0

        middleSum[`${tender.tenderName}_contractNum`] = 0
        middleSum[`${tender.tenderName}_currentMonthNum`] = 0
        middleSum[`${tender.tenderName}_currentDateNum`] = 0
        middleSum[`${tender.tenderName}_accumulateNum`] = 0
        middleSum[`${tender.tenderName}_realNum`] = 0
      })

      bigSum.total_contractNum = 0
      bigSum.total_currentMonthNum = 0
      bigSum.total_currentDateNum = 0
      bigSum.total_accumulateNum = 0
      bigSum.total_realNum = 0

      middleSum.total_contractNum = 0
      middleSum.total_currentMonthNum = 0
      middleSum.total_currentDateNum = 0
      middleSum.total_accumulateNum = 0
      middleSum.total_realNum = 0

      this.defaultDataList.forEach(item => {
        this.tenders.forEach(tender => {
          if (item[`${tender.tenderName}_code`] === 'D') {
            if (!isNaN(item[`${tender.tenderName}_contractNum`])) {
              bigSum[`${tender.tenderName}_contractNum`] += item[`${tender.tenderName}_contractNum`]
              bigSum.total_contractNum += item[`${tender.tenderName}_contractNum`]
            }
            if (!isNaN(item[`${tender.tenderName}_currentMonthNum`])) {
              bigSum[`${tender.tenderName}_currentMonthNum`] += item[`${tender.tenderName}_currentMonthNum`]
              bigSum.total_currentMonthNum += item[`${tender.tenderName}_currentMonthNum`]
            }
            if (!isNaN(item[`${tender.tenderName}_currentDateNum`])) {
              bigSum[`${tender.tenderName}_currentDateNum`] += item[`${tender.tenderName}_currentDateNum`]
              bigSum.total_currentDateNum += item[`${tender.tenderName}_currentDateNum`]
            }
            if (!isNaN(item[`${tender.tenderName}_accumulateNum`])) {
              bigSum[`${tender.tenderName}_accumulateNum`] += item[`${tender.tenderName}_accumulateNum`]
              bigSum.total_accumulateNum += item[`${tender.tenderName}_accumulateNum`]
            }
            if (!isNaN(item[`${tender.tenderName}_realNum`])) {
              bigSum[`${tender.tenderName}_realNum`] += item[`${tender.tenderName}_realNum`]
              bigSum.total_realNum += item[`${tender.tenderName}_realNum`]
            }
          } else if (item[`${tender.tenderName}_code`] === 'ZX') {
            if (!isNaN(item[`${tender.tenderName}_contractNum`])) {
              middleSum[`${tender.tenderName}_contractNum`] += item[`${tender.tenderName}_contractNum`]
              middleSum.total_contractNum += item[`${tender.tenderName}_contractNum`]
            }
            if (!isNaN(item[`${tender.tenderName}_currentMonthNum`])) {
              middleSum[`${tender.tenderName}_currentMonthNum`] += item[`${tender.tenderName}_currentMonthNum`]
              middleSum.total_currentMonthNum += item[`${tender.tenderName}_currentMonthNum`]
            }
            if (!isNaN(item[`${tender.tenderName}_currentDateNum`])) {
              middleSum[`${tender.tenderName}_currentDateNum`] += item[`${tender.tenderName}_currentDateNum`]
              middleSum.total_currentDateNum += item[`${tender.tenderName}_currentDateNum`]
            }
            if (!isNaN(item[`${tender.tenderName}_accumulateNum`])) {
              middleSum[`${tender.tenderName}_accumulateNum`] += item[`${tender.tenderName}_accumulateNum`]
              middleSum.total_accumulateNum += item[`${tender.tenderName}_accumulateNum`]
            }
            if (!isNaN(item[`${tender.tenderName}_realNum`])) {
              middleSum[`${tender.tenderName}_realNum`] += item[`${tender.tenderName}_realNum`]
              middleSum.total_realNum += item[`${tender.tenderName}_realNum`]
            }
          }
        })
      })

      this.tenders.forEach(tender => {
        if (this.spreadInfo.columns.filter(column => column.label === tender.tenderName).length === 0) {
          const column = {
            label: tender.tenderName,
            children: [
              {
                label: '合同规定进场数量',
                dataKey: `${tender.tenderName}_contractNum`,
                width: 100
              },
              {
                label: '已进场数量',
                dataKey: `${tender.tenderName}_realNum`,
                width: 100
              },
              {
                label: '本日进场数量',
                dataKey: `${tender.tenderName}_currentDateNum`,
                width: 100
              },
              {
                label: '本月进场数量',
                dataKey: `${tender.tenderName}_currentMonthNum`,
                width: 100
              },
              {
                label: '累计进场数量',
                dataKey: `${tender.tenderName}_accumulateNum`,
                width: 100
              }
            ]
          }
          this.spreadInfo.columns.push(column)
        }
      })

      this.defaultDataList.unshift(middleSum)
      this.defaultDataList.unshift(bigSum)

      this.$loading().close()
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      this.g20Map = {}
      this.spreadInfo.columns = [
        {
          label: '机械设备名称',
          dataKey: 'machineName',
          width: 200
        },
        {
          label: '单位',
          dataKey: 'unit',
          width: 90
        }
      ]
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      const year = parseInt(this.reportDate.substr(0, 4))
      const month = parseInt(this.reportDate.substr(4, 2))
      const date = parseInt(this.reportDate.substr(6, 2))
      g20Service.getTenderStat1(year, month, date, auth.getUserInfo().projectGuid, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data
            this.getMachineTypes()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    },
    getMachineTypes () {
      machineTypeService.list()
        .then(res => {
          this.machineTypes = res.data.sort(item => item.sortId)
          this.generateMapData()
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载列表失败'
          })
        })
    },
    getTenders () {
      tenderService.list({ pageIndex: 1, pageSize: 200 }, { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          this.tenders = res.data.data.filter(tender => tender.engineeringType === this.engineeringType)
          this.getData()
        })
    }
  },
  created () {
    this.getTenders()
  }
}
</script>

<style scoped lang="scss">
.g20-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
