import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g20'

let tenderStat1Url = 'tenderStats_1'
let tenderStat2Url = 'tenderStats_2'

class G20Service extends RestService {
  getTenderStat1 (year, month, date, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStat1Url), {
      params: {
        year,
        month,
        date,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat2 (year, month, date, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStat2Url), {
      params: {
        year,
        month,
        date,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g20Service = new G20Service(resourceName)

export default g20Service
